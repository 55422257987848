<template>
  <div>
    <v-card>
      <v-card-title>{{ title }}</v-card-title>

      <v-card-text>
        <v-row class="mt-3">
          <v-col>
            <v-text-field
              label="Tema"
              v-model="form.theme"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-3">
          <v-col>
            <v-select
              v-model="form.StateEntityId"
              :items="stateEntitiesOptions"
              label="Poder"
              class="required"
              :loading="loadingStateEntities"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row class="mt-3">
          <v-col>
            <v-switch
              v-model="form.useAI"
              label="Usar assistente virtual (IA)"
              class="required"
            >
            </v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <vue-editor
              :editorOptions="editorOptions"
              v-model="form.content"
            ></vue-editor>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
              small
              class="primary darken-1 mr-2"
              @click="save()"
              :loading="loading"
            >
              <v-icon left>mdi-content-save</v-icon> {{ saveButtonText }}
            </v-btn>
            <v-btn
              outlined
              small
              text
              @click="closeOrCancel()"
              :loading="loading"
            >
              <v-icon left>mdi-close</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Operação realizada com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeOrCancel()">Ok</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import { VueEditor } from "vue2-editor";

export default {
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    fromDialeticInstitution: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueEditor,
  },
  computed: {
    roomId() {
      return this.$route.params.roomId;
    },
    gameId() {
      return this.mode === "update" ? this.$route.params.gameId : null;
    },
    saveButtonText() {
      return this.mode === "insert" ? "Cadastrar" : "Salvar alterações";
    },
  },
  data() {
    return {
      formPatient: null,
      form: {
        content: "",
      },
      loading: false,
      loadingStateEntities: false,
      successDialog: false,
      editorOptions: {
        modules: { toolbar: [["video", "link"], [{ align: ["justify"] }]] },
      },
      stateEntitiesOptions: [],
    };
  },
  methods: {
    validateForm() {
      const errors = [];

      if (!this.form.theme) errors.push("O campo TEMA é obrigatório");

      if (!this.form.StateEntityId) errors.push("O campo PODER é obrigatório");

      if (this.form.useAI === null || this.form.useAI === undefined)
        errors.push("O campo USAR ASSISTENTE VIRTUAL é obrigatório");

      if (this.form.content === "" || !this.form.content)
        errors.push("Informe um texto inicial para o debate!");

      return errors;
    },
    save() {
      const errors = this.validateForm();

      if (errors.length) return this.$root.$errorDialog(errors);

      this[this.mode]();
    },
    async insert() {
      try {
        this.loading = true;
        let url;
        const dataToSend = { ...this.form };

        if (this.fromDialeticInstitution) {
          url = `admin/jogos/dialeticos`;
          dataToSend.DialeticId = 1;
        } else {
          url = `admin/salas/${this.roomId}/jogos`;
        }

        await this.$axios.post(url, dataToSend);

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        let url = this.fromDialeticInstitution
          ? `admin/jogos/${this.gameId}/dialeticos`
          : `admin/salas/${this.roomId}/jogos/${this.gameId}`;

        await this.$axios.put(url, this.form);

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async loadStateEntities() {
      try {
        this.loadingStateEntities = true;

        const response = await this.$axios.get(`/poderes`);

        this.setEntities(response.data);
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        errorHandled.map((e) => this.$toast.error(e));
      } finally {
        this.loadingStateEntities = false;
      }
    },
    setEntities(data) {
      const cpData = [...data];

      this.stateEntitiesOptions = cpData.map((e) => ({
        text: e.name,
        value: e.id,
      }));

      this.stateEntitiesOptions.unshift({ text: "Selecionar", value: null });

      if (this.$route.params.entityId)
        this.form.StateEntityId = parseInt(this.$route.params.entityId);
    },
    async initializeForm() {
      try {
        this.loading = true;

        const res = await this.$axios.get(`/jogos/${this.gameId}`);

        this.form = { ...res.data };
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
    closeOrCancel() {
      if (this.modal) this.$emit("close-dialog");
      else this.$router.go(-1);
    },
  },
  created() {
    this.loadStateEntities();
    if (this.mode === "update") this.initializeForm();
  },
};
</script>

<style scoped>
.required label::after {
  content: " *";
  color: red;
}
</style>
